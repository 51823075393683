interface XListProps {
  children: React.ReactNode;
}

export const XList = (props: XListProps) => {
  return (
    <div className="w-full flex flex-row gap-10 overflow-x-auto whitespace-nowrap">
      {props.children}
    </div>
  );
};
