import { TextField } from "@mui/material";
import { useField } from "formik";
import { ChangeEventHandler, FocusEventHandler } from "react";

interface XFTextFieldProps {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  error?: boolean;
  touched?: boolean;
  focus?: boolean;
}

const XFTextField = (props: XFTextFieldProps) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;

  return (
    <div className="flex flex-col flex-1 gap-3">
      <TextField
        {...field}
        {...props}
        name={props.name}
        type={props.type}
        label={props.label}
        placeholder={props.placeholder}
        InputProps={{
          classes: {
            root: `rounded-xl ${hasError ? "border-error" : "border-gray-300"}`,
            notchedOutline: `border ${hasError ? "border-error" : "border-gray-300"}`,
            input: "text-md text-white px-4 py-3",
          },
        }}
        InputLabelProps={{
          classes: {
            root: `px-1 top-[-4px] ${hasError ? "text-error" : "text-white/20"}`,
            focused: `${hasError ? "text-error" : "text-white/20"}`,
          },
        }}
      />
      {hasError && <span className="text-xs text-error">{meta.error}</span>}
    </div>
  );
};

export default XFTextField;
