import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

const useAuth = () => {
  const auth = getAuth();
  const [userId, setUserId] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in.
      setUserId(user.uid);
      setDisplayName(user.displayName);
      // Access other user properties like user.email, user.displayName, etc.
    } else {
      // User is signed out.
    }
  });

  return {
    userId,
    displayName,
  };
};
export default useAuth;
