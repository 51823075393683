import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface XTableProps {
  rows: any[];
  columns: GridColDef[];
}

const XTable = (props: XTableProps) => {
  const paginationModel = { page: 0, pageSize: 25 };
  return (
    <DataGrid
      rows={props.rows}
      columns={props.columns}
      initialState={{ pagination: { paginationModel } }}
      pageSizeOptions={[25, 50]}
      sx={{
        background: "rgba(0,0,0,0.1)",
        border: 2,
        borderColor: "rgba(255,255,255,0.1)",
        borderRadius: 5,
        "& .MuiDataGrid-columnHeader": {
          fontSize: 16,
          fontWeight: 700,
          background: "#181818",
          px: 2,
        },
        "& .MuiDataGrid-cell": {
          color: "rgba(255,255,255, 0.8)",
          fontWeight: 400,
          px: 2,
        },
        "& .MuiToolbar-root p": {
          fontWeight: 400,
        },
      }}
    />
  );
};

export default XTable;
