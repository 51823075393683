import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import routes from "router/routes";
import XMenuItem from "components/XMenuItem";
import { useNavigate } from "react-router-dom";

interface AccountMenuProps {
  initial?: string;
}

const AccountMenu = (props: AccountMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
      window.location.reload(); // Reload the current page to clear any user-specific data
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <>
      <div className="flex items-center text-center">
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="large"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar className="bg-primary text-primaryOver w-[40px] h-[40px]">
              {props.initial}
            </Avatar>
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "& ul": {
                width: "180px",
                bgcolor: "#121212",
                border: "0px solid #263238",
                borderRadius: "10px",
                top: -10,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: -10,
                right: 24,
                width: 16,
                height: 16,
                bgcolor: "#121212",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <XMenuItem to={routes.Artists.create.path} label="Create Artist" />
        <XMenuItem to={routes.Admin.Events.Create.path} label="Create Event" />
        <Divider />
        <XMenuItem to={routes.MyArtists.list.path} label="My Artists" />
        <XMenuItem to={routes.Admin.Events.Create.path} label="My Events" />
        <Divider />
        <XMenuItem
          label="Logout"
          onClick={handleLogout}
          icon={<Logout fontSize="small" />}
        />
      </Menu>
    </>
  );
};

export default AccountMenu;
