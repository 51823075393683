import { validateVendorInput } from "shared/service/utils/InputValidator";
import {
  Vendor,
  GetObjectInput,
  ListQueryInput,
  UpdateVendorInput,
  ModelResponse,
  CreateVendorInput,
  DeleteObjectInput,
  ListNearByQueryInput,
} from "shared/API";
import {
  FIREBASE_ANALYTICS,
  FIREBASE_AUTH,
  FIREBASE_FUNCTIONS,
  LOGGER,
} from "shared/Firebase";
import {
  IsImageValid,
  getFileDirectory,
  getPictureType,
} from "shared/service/utils/ImageUtil";
import { getFileURL, saveImage } from "shared/service/Storage";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  getFirestoreObjectById,
  listFirestoreObjects,
  listNearbyObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { XStatus } from "shared/service/utils/XStatus";
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";

export const VENDOR_COLLECTION = "vendors";
export const PRIVATE_VENDOR_COLLECTION = "privatevendors";

export async function vendorsAPI(input: {
  query: ListQueryInput;
  isPrivate?: boolean;
}): Promise<ModelResponse<Vendor>> {
  return listFirestoreObjects<Vendor>(
    input.isPrivate ? PRIVATE_VENDOR_COLLECTION : VENDOR_COLLECTION,
    input,
  );
}

export async function vendorByIdAPI(input: {
  vendor: GetObjectInput;
  isPrivate?: boolean;
}): Promise<Vendor> {
  return getFirestoreObjectById<Vendor>(
    input.isPrivate ? PRIVATE_VENDOR_COLLECTION : VENDOR_COLLECTION,
    input.vendor,
  );
}

export async function vendorsNearbyAPI(input: {
  isPrivate?: boolean;
  query: ListNearByQueryInput;
}): Promise<Vendor[]> {
  return listNearbyObjects<Vendor>(
    input.isPrivate ? PRIVATE_VENDOR_COLLECTION : VENDOR_COLLECTION,
    input.query,
  );
}

export async function createVendorAPI(input: {
  vendor: CreateVendorInput;
  image?: File;
  isPrivate?: boolean;
}): Promise<string> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  const vendorId = await createFirestoreObjectPrivileged<CreateVendorInput>(
    input.isPrivate ? PRIVATE_VENDOR_COLLECTION : VENDOR_COLLECTION,
    input.vendor,
    validateVendorInput,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    LOGGER!.info(input);
    const upload_result = await saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: `${vendorId}.${getPictureType(input.image)}`,
      file: input.image,
    });
    {
      input.vendor.imageUrl = await getFileURL(upload_result.ref.fullPath);
      await updateVendorAPI({
        vendor: { id: vendorId, imageUrl: input.vendor.imageUrl },
        isPrivate: input.isPrivate,
      });
    }
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "vendor_creation", { uid });
  }
  return vendorId;
}

export async function deleteVendorAPI(input: {
  vendor: DeleteObjectInput;
  isPrivate?: boolean;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "vendor_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(
    input.isPrivate ? PRIVATE_VENDOR_COLLECTION : VENDOR_COLLECTION,
    input.vendor,
  );
}

export async function updateVendorAPI(input: {
  vendor: UpdateVendorInput;
  image?: File;
  isPrivate?: boolean;
}): Promise<void> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  await updateFirestoreObject<UpdateVendorInput>(
    input.isPrivate ? PRIVATE_VENDOR_COLLECTION : VENDOR_COLLECTION,
    input.vendor,
    validateVendorInput,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    const upload_result = await saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: `${input.vendor.id}.${getPictureType(input.image)}`,
      file: input.image,
    });
    {
      input.vendor.imageUrl = await getFileURL(upload_result.ref.fullPath);
      await updateVendorAPI({
        vendor: { id: input.vendor.id, imageUrl: input.vendor.imageUrl },
        isPrivate: input.isPrivate,
      });
    }
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "vendor_update", { uid });
  }
}

export async function publishVendorAPI(input: { vendorId: string }) {
  const publishVendor = httpsCallable(FIREBASE_FUNCTIONS!, "publishVendor");

  await publishVendor({ vendorId: input.vendorId! })
    .then(() => {
      expect(true);
    })
    .catch(() => {
      expect(false);
    });
}
