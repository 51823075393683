import { Event, Vendor } from "shared/API";

export const ivArtistMutation = (artist?: Vendor) => {
  return {
    username: artist?.username || "",
    displayName: artist?.displayName || "",
    headline: artist?.headline || "",
    audience: "",
    description: artist?.description || "",
    addressStreet1: artist?.address?.street1 || "",
    addressStreet2: artist?.address?.street2 || "",
    addressCity: artist?.address?.city || "",
    addressState: artist?.address?.state || "",
    addressCountry: artist?.address?.country || "",
    addressPostalCode: artist?.address?.postalCode || "",
    contactPhone: artist?.contact?.phones[0] || "",
    contactEmail: artist?.contact?.emails[0] || "",
    // instagram: artist?.socialMedia?.instagram || "",
    // website: artist?.socialMedia?.website || "",
    // facebook: artist?.socialMedia?.facebook || "",
    // youtube: artist?.socialMedia?.youtube || "",
    // tikTok: artist?.socialMedia?.tikTok || "",
    // twitter: artist?.socialMedia?.twitter || "",
    // telegram: artist?.socialMedia?.telegram || "",
    // soundCloud: artist?.socialMedia?.soundCloud || "",
    // spotify: artist?.socialMedia?.spotify || "",
    // appleMusic: artist?.socialMedia?.appleMusic || "",
    // whatsapp: artist?.socialMedia?.whatsapp || "",
    // onlyFans: artist?.socialMedia?.onlyFans || "",
    image: "",
    types: [],
  };
};

export const ivEventMutation = (event?: Event) => {
  return {
    title: event?.title || "",
    description: event?.description || "",
    addressStreet1: event?.address?.street1 || "",
    addressStreet2: event?.address?.street2 || "",
    addressCity: event?.address?.city || "",
    addressState: event?.address?.state || "",
    addressCountry: event?.address?.country || "",
    addressPostalCode: event?.address?.postalCode || "",
    ticketLink: event?.ticketLink || "",
    contactPhone: event?.contact?.phones[0] || "",
    contactEmail: event?.contact?.emails[0] || "",
    image: "",
  };
};
