import { Box, SxProps } from "@mui/material";
import Page from "components/Page";

export const Home = () => {
  const sxHeroContainer: SxProps = {
    height: "calc(60vh)",
    filter: "grayscale(100%)",
    position: "relative",
  };
  const sxHeroContent: SxProps = {
    textAlign: "center",
    left: 0,
    right: 0,
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    "& h1": {
      fontSize: 40,
      fontWeight: 400,
      lineHeight: "60px",
    },
    "& h3": {
      fontSize: 20,
      fontWeight: 300,
      lineHeight: "40px",
    },
  };

  return (
    <Page>
      <Box sx={sxHeroContainer}>
        <Box sx={sxHeroContent}>
          <h1>iPlanX: Planning Redefined</h1>
          <h3>
            Elevate every event with iPlanX, where simplicity meets excitement.
          </h3>
        </Box>
      </Box>
    </Page>
  );
};
