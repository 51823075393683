import {
  XFAutocomplete,
  XFAddressFields,
  XFButton,
  XFContactFields,
  XFForm,
  XFRow,
  XFSocialMediaFields,
  XFTextField,
  XFUpload,
} from "components/forms";
import Page from "components/Page";
import { Formik, FormikValues } from "formik";
import { schemaArtistCreate } from "utils/validation/schema";
import { ENUMArtistType, ENUMAudience } from "shared/service/utils/enums";
import { updateVendorAPI, vendorByIdAPI } from "shared/service/Vendor";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ivArtistMutation } from "utils/initialValues";
import { useMutation, useQuery } from "@tanstack/react-query";
import routes from "router/routes";
import XFTextarea from "components/forms/XFTextarea";
const ArtistUpdatePage = () => {
  const { id, userId } = useParams();
  const [initialValues, setInitialValues] = useState(ivArtistMutation());
  const [originalValues, setOriginalValues] = useState<FormikValues>({});
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    data: artist,
    isLoading,
    error: vendorQueryError,
  } = useQuery({
    queryKey: ["ArtistUpdatePage.vendorByIdAPI", id],
    queryFn: () =>
      vendorByIdAPI({
        vendor: { id: String(id) },
        isPrivate: Boolean(userId),
      }),
    enabled: !!id,
  });

  const { mutate: updateVendor, error: updateVendorError } = useMutation({
    mutationKey: ["ArtistUpdatePage.updateVendor", id],
    mutationFn: updateVendorAPI,
  });

  useEffect(() => {
    const values = ivArtistMutation(artist);
    setInitialValues(values);
    setOriginalValues(values);
    if (updateVendorError?.message) setError(updateVendorError.message);
    if (vendorQueryError?.message) setError(vendorQueryError.message);
    error && console.log(error);
  }, [artist, updateVendorError, vendorQueryError]);

  const handleSubmit = (values: FormikValues) => {
    const changedFields = Object.keys(values).reduce((acc, key) => {
      if (values[key] !== originalValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {} as FormikValues);

    if (id) {
      updateVendor({
        vendor: {
          id,
          ...changedFields,
        },
        image: changedFields?.image,
      });
    }
    navigate(routes.Artists.list.path);
  };

  return (
    <Page>
      {!isLoading && (
        <Formik
          initialValues={initialValues}
          validationSchema={schemaArtistCreate}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue }) => (
            <XFForm>
              <h4>Account Info</h4>
              <XFRow>
                <XFTextField label="Username" name="username" />
                <XFTextField label="Display Name" name="displayName" />
              </XFRow>
              <XFTextField label="Headline" name="headline" />
              <XFTextarea label="Description" name="description" />
              <XFAutocomplete
                label="Types"
                name="types"
                options={ENUMArtistType}
              />
              <XFAutocomplete
                label="Audience"
                name="audience"
                options={ENUMAudience}
              />
              <hr />
              <h4>Profile Picture</h4>
              <XFUpload
                name="image"
                onChange={(file) => {
                  setFieldValue("image", file);
                }}
                imageUrl={artist?.imageUrl}
              />
              <hr />
              <h4>Address</h4>
              <XFAddressFields />
              <hr />
              <h4>Contact</h4>
              <XFContactFields />
              <hr />
              <h4>Social Media</h4>
              <XFSocialMediaFields />
              <XFButton
                title="Save Changes"
                type="submit"
                disabled={isSubmitting}
              />
            </XFForm>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default ArtistUpdatePage;
