import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useField } from "formik";
import { TextField } from "@mui/material";

interface XFAutocompleteProps {
  label: string;
  name: string;
  options: XFAutocompleteOption[];
}

export interface XFAutocompleteOption {
  label: string;
  value: string;
}

const XFAutocomplete: React.FC<XFAutocompleteProps> = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const { setValue } = helpers;
  const hasError = meta.touched && !!meta.error;

  const currentValue: XFAutocompleteOption[] = Array.isArray(field.value)
    ? field.value
    : [];

  return (
    <div className="flex flex-col flex-1 gap-3">
      <Autocomplete
        multiple
        limitTags={3}
        options={props.options}
        getOptionLabel={(option) => option.label || ""}
        value={currentValue}
        onChange={(event, newValue: XFAutocompleteOption[]) => {
          setValue(newValue || []);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            error={hasError}
            helperText={hasError ? meta.error : ""}
            sx={{
              "& .MuiInputLabel-root": {
                px: 1,
                backgroundColor: "#000",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "60px",
                px: 2,
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default XFAutocomplete;
