import {
  CreateObjectInput,
  DeleteObjectInput,
  GeoLocation,
  GetObjectInput,
  ListNearByQueryInput,
  ListQueryInput,
  ModelResponse,
  OwnedObject,
  UpdateObjectInput,
} from "shared/API";
import { isIdValid } from "shared/utils/EntityValidator";
import { XStatus } from "shared/service/utils/XStatus";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DATABASE } from "shared/Firebase";
import {
  BuildGeoNearbyQueries,
  buildQuery,
} from "shared/service/utils/QueryBuilder";
import * as geoFire from "geofire-common";

/**
 * Fetch a database object by the internal id
 * @param variable
 * @returns Object on success or in the case of failure:
 * 1) XStatus.NotFound: the given id couldn't be found
 * 2) Error: Any firestore errors
 */
export async function getFirestoreObjectById<ObjectType extends GetObjectInput>(
  collectionName: string,
  input: GetObjectInput,
): Promise<ObjectType> {
  if (!isIdValid(input.id)) {
    throw XStatus.InvalidId;
  }
  const docRef = doc(FIREBASE_DATABASE!, collectionName, input.id);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    throw XStatus.NotFound;
  }
  const data: ObjectType = docSnap.data() as ObjectType;
  data.id = docSnap.id;
  return data;
}

/**
 * Delete Object from a database. This operation is allowed only by the owner.
 * @param variable \
 * @returns
 */
export async function deleteFirestoreObject(
  collectionName: string,
  input: DeleteObjectInput,
): Promise<void> {
  if (!isIdValid(input.id)) {
    throw XStatus.InvalidId;
  }
  await deleteDoc(doc(FIREBASE_DATABASE!, collectionName, input.id));
}

export async function createFirestoreObjectPrivileged<
  ObjectType extends CreateObjectInput,
>(
  collectionName: string,
  input: ObjectType,
  inputValidator: (input: ObjectType) => XStatus,
  idGen?: (input: ObjectType) => string,
): Promise<string> {
  const user = FIREBASE_AUTH?.currentUser;
  if (!user) {
    throw XStatus.NoSignedInUser;
  }
  const validationStatus = inputValidator(input);
  if (validationStatus != XStatus.Valid) {
    throw validationStatus;
  }
  const object = input as ObjectType & OwnedObject;
  if (idGen) {
    object.id = idGen(input);
  }
  object.ownerId = user.uid;
  if (object.id) {
    await setDoc(doc(FIREBASE_DATABASE!, collectionName, object.id), object);
  } else {
    const docRef = await addDoc(
      collection(FIREBASE_DATABASE!, collectionName),
      object,
    );
    object.id = docRef.id;
  }
  return object.id!;
}

export async function createFirestoreObject<
  ObjectType extends CreateObjectInput,
>(
  collectionName: string,
  input: ObjectType,
  inputValidator: (input: ObjectType) => XStatus,
  idGen?: (input: ObjectType) => string,
): Promise<string> {
  const validationStatus = inputValidator(input);
  if (validationStatus != XStatus.Valid) {
    throw validationStatus;
  }
  const object: ObjectType = input;
  if (idGen) {
    object.id = idGen(input);
  }
  if (object.id) {
    await setDoc(doc(FIREBASE_DATABASE!, collectionName, object.id), object);
  } else {
    const docRef = await addDoc(
      collection(FIREBASE_DATABASE!, collectionName),
      object,
    );
    object.id = docRef.id;
  }
  return object.id!;
}

export async function getSubCollectionCount(
  collectionName: string,
  documentId: string,
  subCollectionName: string,
): Promise<number> {
  const subCollectionRef = collection(
    FIREBASE_DATABASE!,
    `${collectionName}/${documentId}/${subCollectionName}`,
  );
  const snapshot = await getCountFromServer(subCollectionRef);
  return snapshot.data().count;
}

export async function updateFirestoreObject<
  ObjectType extends UpdateObjectInput,
>(
  collectionName: string,
  input: ObjectType,
  inputValidator: (input: ObjectType) => XStatus,
  initializeFields?: (input: ObjectType) => object,
): Promise<void> {
  const validationStatus = await inputValidator(input);
  if (validationStatus != XStatus.Valid) {
    throw validationStatus;
  }
  const dbObject: ObjectType = initializeFields
    ? { ...input, ...initializeFields(input) }
    : input;
  const objectRef = doc(FIREBASE_DATABASE!, collectionName, dbObject.id);
  await updateDoc(objectRef, dbObject);
}

export async function listFirestoreObjects<ObjectType extends GetObjectInput>(
  collectionName: string,
  input: {
    query: ListQueryInput;
  },
): Promise<ModelResponse<ObjectType>> {
  const q = buildQuery(FIREBASE_DATABASE!, collectionName, input.query);
  const querySnapshot = await getDocs(q);
  const response: ModelResponse<ObjectType> = { items: [] };
  querySnapshot.forEach((doc) => {
    const dbObject: ObjectType = doc.data() as ObjectType;
    dbObject.id = doc.id;
    response.items.push(dbObject);
  });
  return response;
}

export async function listNearbyObjects<ObjectType>(
  collectionName: string,
  input: ListNearByQueryInput,
): Promise<ObjectType[]> {
  const promises = [];
  const queries = BuildGeoNearbyQueries(
    FIREBASE_DATABASE!,
    collectionName,
    input,
  );
  for (const q of queries) {
    promises.push(getDocs(q));
  }

  // Collect all the query results together into a single list
  return Promise.all(promises).then((snapshots) => {
    const matchingDocs: ObjectType[] = [];
    for (const snap of snapshots) {
      for (const doc of snap.docs) {
        const location = doc.get("location") as GeoLocation;
        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = geoFire.distanceBetween(
          [location.latitude, location.longitude],
          [input.center.latitude, input.center.longitude],
        );
        if (distanceInKm <= input.radiusInKiloMeter) {
          matchingDocs.push(doc.data() as ObjectType);
        }
      }
    }

    return matchingDocs;
  });
}
