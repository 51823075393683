import { ChangeEvent, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
// import XConfirmationModal from "components/XConfirmationModal";
// import { deleteImage } from "shared/service/Storage";
// import useAuth from "hooks/useAuth";

interface XFUploadProps {
  name: string;
  onChange: (file: File | null) => void;
  imageUrl?: string;
}

const XFUpload = (props: XFUploadProps) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [fileTitle, setFileTitle] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // TODO: MR
  // const [modalOpen, setModalOpen] = useState(false); // Uncomment this if you want to show the modal
  // const { userId } = useAuth();

  // const handleDelete = () => {
  //   console.log("Item deleted!");
  //   if (userId && fileTitle) {
  //     deleteImage(userId, fileTitle); // Assuming deleteImage needs the fileName (fileTitle)
  //     setFileTitle(""); // Clear file title after deletion
  //     props.onChange(null); // Notify parent component to clear file
  //   }
  // };

  // const handleCancel = () => {
  //   console.log("Action canceled!");
  //   // Close the modal if you're using one
  //   // setModalOpen(false);
  // };

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragEvents = {
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setIsDragOver(false);
      const file = e.dataTransfer.files[0];
      if (file) {
        setFileTitle(file.name);
        props.onChange(file); // Pass the file to the onChange callback
      }
    },
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setFileTitle(file.name);
      props.onChange(file); // Pass the file to the onChange callback
    }
  };

  const handleRemove = () => {
    setFileTitle(""); // Reset fileTitle state
    props.onChange(null); // Clear the file in the parent component
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input field
    }
  };

  return (
    <div className="flex flex-col flex-1 gap-4">
      <input
        type="file"
        className="hidden"
        id="file-upload"
        onChange={handleChange}
        ref={fileInputRef}
      />
      {props.imageUrl ? (
        <div className="relative w-[200px]">
          <img className="w-[200px] rounded-[100%]" src={props.imageUrl} />
          <div className="absolute p-2 right-3 bottom-3 bg-black/80 rounded-lg">
            <DeleteIcon
              className="cursor-pointer hover:text-[#a3a3a3]"
              fontSize="medium"
              onClick={handleRemove}
            />
          </div>
        </div>
      ) : (
        !fileTitle && (
          <label
            htmlFor="file-upload"
            {...dragEvents}
            className={`h-[250px] bg-white/1 border border-white/20 rounded-lg flex gap-4 cursor-pointer text-center items-center justify-center ${isDragOver && "bg-white/5"} hover:bg-white/5`}
          >
            <CloudUploadIcon fontSize="large" />
            <span>Click or drag to upload file</span>
          </label>
        )
      )}
      {fileTitle && (
        <div className="w-full flex items-center gap-5 px-5 py-4 border border-white/20 rounded-xl bg-white/10">
          <div className="flex flex-row gap-5 flex-1">
            <InsertLinkIcon fontSize="medium" />
            <span>{fileTitle}</span>
          </div>
          <DeleteIcon
            className="cursor-pointer hover:text-[#a3a3a3]"
            fontSize="medium"
            onClick={handleRemove}
          />
        </div>
      )}
      {/* {modalOpen && (
        <XConfirmationModal
          open={modalOpen}
          message="You're about to remove the photo. Are you sure you want to do that?"
          onConfirm={handleDelete}
          onCancel={handleCancel}
        />
      )} */}
    </div>
  );
};

export default XFUpload;
