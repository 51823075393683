import XFAddressFields from "components/forms/XFAddressFields";
import XFButton from "components/forms/XFButton";
import XFContactFields from "components/forms/XFContactFields";
import XFForm from "components/forms/XFForm";
import XFTextField from "components/forms/XFTextField";
import Page from "components/Page";
import { Formik, FormikValues } from "formik";
import { schemaEventCreate } from "utils/validation/schema";
import { eventByIdAPI, updateEventAPI } from "shared/service/Event";
import { ivEventMutation } from "utils/initialValues";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import XFUpload from "components/forms/XFUpload";
import { useMutation, useQuery } from "@tanstack/react-query";
import routes from "router/routes";

const EventUpdatePage = () => {
  const { id, userId } = useParams();
  const [initialValues, setInitialValues] = useState(ivEventMutation());
  const [originalValues, setOriginalValues] = useState<FormikValues>({});
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const { data: event, error: eventQueryError } = useQuery({
    queryKey: ["EventUpdatePage.eventByIdAPI"],
    queryFn: () =>
      eventByIdAPI({
        event: { id: String(id) },
        isPrivate: Boolean(userId),
      }),
    enabled: !!id,
  });

  const { mutate: updateEvent, error: updateVendorError } = useMutation({
    mutationKey: ["ArtistUpdatePage.updateVendor", id],
    mutationFn: updateEventAPI,
  });

  useEffect(() => {
    const values = ivEventMutation(event);
    setInitialValues(values);
    setOriginalValues(values);
    if (updateVendorError?.message) setError(updateVendorError.message);
    if (eventQueryError?.message) setError(eventQueryError.message);
    error && console.log(error);
  }, [event, updateVendorError, eventQueryError]);

  const handleSubmit = (values: FormikValues) => {
    const changedFields = Object.keys(values).reduce((acc, key) => {
      if (values[key] !== originalValues[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {} as FormikValues);

    if (id) {
      updateEvent({
        event: {
          id,
          ...changedFields,
        },
        image: changedFields?.image,
      });
    }
    navigate(routes.Artists.list.path);
  };

  return (
    <Page title="Create Event">
      <Formik
        initialValues={initialValues}
        validationSchema={schemaEventCreate}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <XFForm>
            <XFTextField label="Title" name="title" />
            <XFTextField label="Description" name="description" />
            <XFUpload
              name="image"
              onChange={(file) => {
                setFieldValue("image", file);
              }}
            />
            <XFTextField label="Ticket Link" name="ticketLink" />
            <XFAddressFields />
            <XFContactFields />
            {/* <XFAutocomplete label="Tags" name="tags" /> */}
            <XFButton title="Submit" type="submit" disabled={isSubmitting} />
          </XFForm>
        )}
      </Formik>
    </Page>
  );
};

export default EventUpdatePage;
