import XFRow from "./XFRow";
import XFTextField from "./XFTextField";

const XFAddressFields = () => {
  return (
    <>
      <XFRow>
        <XFTextField name="addressStreet1" label="Street Address" />
        <XFTextField name="addressStreet2" label="Street Address 2" />
      </XFRow>
      <XFRow>
        <XFTextField name="addressCity" label="City" />
        <XFTextField name="addressState" label="State" />
      </XFRow>
      <XFRow>
        <XFTextField name="addressCountry" label="Country" />
        <XFTextField name="addressPostalCode" label="Postal Code" />
      </XFRow>
    </>
  );
};

export default XFAddressFields;
