import React from "react";
import { Link } from "react-router-dom";

interface XMenuItemProps {
  label: string;
  to?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const XMenuItem = (props: XMenuItemProps) => {
  const XMenuItemStyle =
    "flex items-center cursor-pointer py-3 px-5 gap-2 hover:bg-[#181818]";
  if (props.onClick) {
    return (
      <div onClick={props.onClick} className={XMenuItemStyle}>
        {props.icon && <span>{props.icon}</span>}
        <span>{props.label}</span>
      </div>
    );
  } else if (props.to) {
    return (
      <Link to={props.to} className={XMenuItemStyle}>
        {props.icon && <span>{props.icon}</span>}
        <span>{props.label}</span>
      </Link>
    );
  } else {
    return <></>;
  }
};

export default XMenuItem;
