import { XFAutocompleteOption } from "components/forms/XFAutocomplete";
import {
  XFAutocomplete,
  XFButton,
  XFForm,
  XFRow,
  XFTextField,
  XFUpload,
} from "components/forms";
import Page from "components/Page";
import { Formik, FormikValues } from "formik";
import { schemaArtistCreate } from "utils/validation/schema";
import { ENUMArtistType } from "shared/service/utils/enums";
import { createVendorAPI } from "shared/service/Vendor";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import routes from "router/routes";

const ArtistCreatePage = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const { mutate: createVendor, error: createVendorError } = useMutation({
    mutationKey: ["ArtistCreatePage.createVendor"],
    mutationFn: createVendorAPI,
  });

  useEffect(() => {
    if (createVendorError?.message) setError(createVendorError.message);
    console.log(error);
  }, [createVendorError]);

  const handleSubmit = (values: FormikValues) => {
    const vendor = {
      username: values.username,
      displayName: values.displayName,
      headline: values.headline,
      types: values.types.map((x: XFAutocompleteOption) => x.value),
    };
    createVendor({ vendor, image: values?.image });
    navigate(routes.Artists.list.path);
  };

  const initialValues = {
    username: "",
    displayName: "",
    headline: "",
    types: [],
    image: "",
  };

  return (
    <Page title="Create Artist">
      <Formik
        initialValues={initialValues}
        validationSchema={schemaArtistCreate}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <XFForm>
            <XFRow>
              <XFTextField label="Username" name="username" />
              <XFTextField label="Display Name" name="displayName" />
            </XFRow>
            <XFTextField label="Headline" name="headline" />
            <XFAutocomplete
              label="Types"
              name="types"
              options={ENUMArtistType}
            />
            <XFUpload
              name="image"
              onChange={(file) => {
                setFieldValue("image", file);
              }}
            />
            <XFButton title="Submit" type="submit" disabled={isSubmitting} />
          </XFForm>
        )}
      </Formik>
    </Page>
  );
};

export default ArtistCreatePage;
