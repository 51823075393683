import { XFAutocompleteOption } from "components/forms/XFAutocomplete";

export const ENUMArtistType: XFAutocompleteOption[] = [
  {
    label: "Singer",
    value: "Singer",
  },
  {
    label: "Deejay",
    value: "Deejay",
  },
  {
    label: "Lighting",
    value: "Lighting",
  },
  {
    label: "Effect",
    value: "Effect",
  },
  {
    label: "American",
    value: "American",
  },
];

export const ENUMAudience: XFAutocompleteOption[] = [
  {
    label: "Afghan",
    value: "Afghan",
  },
  {
    label: "Arab",
    value: "Arab",
  },
  {
    label: "Armenian",
    value: "Armenian",
  },
  {
    label: "Jewish",
    value: "Jewish",
  },
  {
    label: "Dance Instructor",
    value: "DanceInstructor",
  },
];

export const ENUMTags: XFAutocompleteOption[] = [
  {
    label: "Afghan",
    value: "Afghan",
  },
  {
    label: "Arab",
    value: "Arab",
  },
  {
    label: "Armenian",
    value: "Armenian",
  },
  {
    label: "Jewish",
    value: "Jewish",
  },
  {
    label: "Dance Instructor",
    value: "DanceInstructor",
  },
];
