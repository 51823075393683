import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { XFButton, XFTextField } from "components/forms";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import routes from "router/routes";

const Footer = () => {
  const handleSubscribe = () => {
    return;
  };
  return (
    <footer className="w-full">
      <div className="w-full bg-lightGray/10 py-[60px]">
        <div className="flex flex-row gap-30 container max-w-[1200px] mx-auto">
          <div className="flex flex-col flex-1 gap-8">
            <div className="flex items-start gap-[50px]">
              <img
                className="w-[68px] mb-3"
                src="/images/iPlanX-Logo-White.png"
              />
              <div className="flex gap-8">
                <Link to="https://www.facebook.com/iplanx">
                  <Facebook fontSize="large" />
                </Link>
                <Link to="https://www.instagram.com/iplanxofficial">
                  <Instagram fontSize="large" />
                </Link>
                <Link to="https://www.youtube.com/iplanxofficial">
                  <YouTube fontSize="large" />
                </Link>
              </div>
            </div>
            <Formik onSubmit={handleSubscribe} initialValues={{ email: "" }}>
              <Form className="flex flex-row gap-2 max-w-[400px]">
                <XFTextField label="Email" name="email" />
                <XFButton title="Subscribe" type="submit" variant="secondary" />
              </Form>
            </Formik>
          </div>
          <div className="flex flex-1">
            <div className="flex flex-col flex-1 gap-4">
              <Link to={routes.Login.path}>Artists</Link>
              <Link to={routes.Login.path}>Events</Link>
              <Link to={routes.Login.path}>Contact</Link>
            </div>
            <div className="flex flex-col flex-1 gap-4">
              <Link to={routes.Login.path}>About</Link>
              <Link to={routes.Login.path}>Terms & Condition</Link>
              <Link to={routes.Login.path}>Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-lightGray/5 py-5">
        <div className="container max-w-[1200px] mx-auto text-center">
          <p>&copy; 2023 iPlanX. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
