import { ReactNode } from "react";
import { XFButton } from "./forms";

interface IPageProps {
  title?: string;
  action?: {
    label: string;
    href: string;
  };
  children: ReactNode;
}

const Page = (props: IPageProps) => {
  return (
    <>
      {(props.title || props.action) && (
        <div className="flex flex-row items-center justify-between">
          {props.title && <h1 className="mt-0">{props.title}</h1>}
          {props.action && (
            <XFButton
              variant="secondary"
              link={props.action.href}
              title={props.action.label}
            />
          )}
        </div>
      )}
      {props.children}
    </>
  );
};

export default Page;
