import { XStatus } from "shared/service/utils/XStatus";

export function imageTypeIsValid(t: string) {
  const type = t.toLowerCase();
  return type === "jpg" || type === "png" || type === "jpeg";
}

export const generateProfilePictureStorageKey = (id: string, type: string) =>
  `${id}-profile.${type}`;
export const generateCoverPictureStorageKey = (id: string, type: string) =>
  `${id}-cover.${type}`;

export function getPictureType(picture: File): string {
  return picture.name.split(".").pop()!.toLowerCase();
}

export function getImageType(picture: File) {
  return picture.name.split(".").pop()?.toLowerCase();
}

export function IsImageValid(picture: File): XStatus {
  const type = getImageType(picture);
  if (!type || !imageTypeIsValid(type)) {
    return XStatus.InvalidType;
  }
  return XStatus.Valid;
}

export function getFileDirectory(uid: string, directory: string) {
  return `users/${uid}/${directory}`;
}
