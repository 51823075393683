import Page from "components/Page";
import { XSpace } from "components/common/XSpace";
import { useParams } from "react-router-dom";
import { vendorByIdAPI } from "shared/service/Vendor";
import {
  testEventListItem1,
  testEventListItem2,
  testEventListItem3,
} from "shared/service/__test__/TestData";
import { useQuery } from "@tanstack/react-query";
import { XFButton } from "components/forms";
import XChips from "components/XChip";
import XTitles from "components/XTitles";
import { extractLocation } from "utils/address";
import { XHighlights } from "components/XHighlights";
// import XSocials from "components/XSocials";
import { XItemEvents } from "components/XItemEvent";
import { XTabsVertical } from "components/XTabsVertical";

const ArtistDetailsPage = () => {
  const { id } = useParams();

  // useBodyBackground({
  //   overlayImageUrl:
  //     "https://i.scdn.co/image/ab6761610000517419187925adb538bd1d943b93",
  // });

  const { data: artist } = useQuery({
    queryKey: ["ArtistDetailsPage.eventByIdAPI", id],
    queryFn: () =>
      vendorByIdAPI({
        vendor: { id: String(id) },
      }),
    enabled: !!id,
  });

  if (artist) {
    const itemsXTitles = [];
    if (artist.address)
      itemsXTitles.push({
        label: "Location",
        value: extractLocation(artist.address),
      });
    itemsXTitles.push(
      { label: "Bookings", value: "500+" },
      { label: "Endorsed", value: "25+" },
    );

    const highlights = [
      {
        title: "Deltangam",
        subtitle: "Released on Dec 17, 2024",
        href: "https://open.spotify.com/track/0l4yre7R5Nk896yyiOmlB5?si=bf567b10218e43c9",
        imageUrl:
          "https://i.scdn.co/image/ab67616d0000b273afd1c381c461d4835a437937",
      },
      {
        title: "25 Band",
        subtitle: "Released on Dec 2, 2024",
        href: "https://open.spotify.com/track/7zVF4iyKJDppwZaznkKXHr?si=5014ce7d4d4149c4",
        imageUrl:
          "https://i.scdn.co/image/ab67616d0000b273f0822755badf9747db016c46",
      },
      {
        type: "New Track",
        title: "Bargaye Narenji",
        subtitle: "Released on Nov 17, 2024",
        href: "https://open.spotify.com/track/7edQCl8JdbnGlbz2cRbnaF?si=3480d71cd81f4a98",
        imageUrl:
          "https://i.scdn.co/image/ab67616d00001e02308cf3287ff504ed46fc62b4",
      },
    ];

    const highlights2 = [
      {
        title: "Instagram",
        subtitle: "Released on Dec 17, 2024",
        href: "https://open.spotify.com/track/0l4yre7R5Nk896yyiOmlB5?si=bf567b10218e43c9",
        imageUrl:
          "https://i.scdn.co/image/ab67616d0000b273afd1c381c461d4835a437937",
      },
      {
        title: "Facebook",
        subtitle: "Released on Dec 2, 2024",
        href: "https://open.spotify.com/track/7zVF4iyKJDppwZaznkKXHr?si=5014ce7d4d4149c4",
        imageUrl:
          "https://i.scdn.co/image/ab67616d0000b273f0822755badf9747db016c46",
      },
    ];

    const services = [
      {
        type: "Light",
        description:
          "Create the perfect ambiance with our state-of-the-art lighting services. From dramatic spotlights to subtle mood lighting, our solutions elevate the visual impact of your event and highlight key elements.",
        icon: "/assets/icons/light.svg",
        href: "hh",
      },
      {
        type: "DJ",
        description:
          "Our professional DJs are experts in setting the tone for your event with carefully curated playlists and dynamic performances. Whether it’s a lively party or a sophisticated gathering, they ensure the perfect soundtrack for every moment.",
        icon: "/assets/icons/dj.svg",
        href: "",
      },
      {
        type: "Sound",
        description:
          "Our sound services deliver crystal-clear audio for events of all sizes. From speeches to live performances, we provide top-notch sound systems and technical support to ensure every word and note is heard.",
        icon: "/assets/icons/sound.svg",
        href: "",
      },
      {
        type: "Stage",
        description:
          "We provide high-quality staging solutions tailored to your event's unique needs. Whether you're hosting a concert, fashion show, or corporate event, our staging options ensure a safe, professional, and visually appealing platform for your performers and speakers.",
        icon: "/assets/icons/stage.svg",
        href: "",
      },
      {
        type: "Singer",
        description:
          "Elevate your event with live performances from talented singers. Whether you need a soloist or a group, our vocalists deliver exceptional performances that resonate with your audience.",
        icon: "/assets/icons/singer.svg",
        href: "",
      },
      {
        type: "Visual",
        description:
          "Enhance your event with stunning visual displays, including projectors, LED walls, and immersive media. Our visual solutions create a captivating and interactive experience for your attendees.",
        icon: "/assets/icons/visual.svg",
        href: "",
      },
    ];

    return (
      <Page title="">
        <div className="w-full flex items-center gap-10">
          <img src={artist.imageUrl} className="w-[280px] rounded-[50%]" />
          <div className="flex flex-col flex-1 gap-4">
            <h1 className="flex gap-2 items-center text-xxl font-medium leading-8 m-0 mb-4">
              {artist.displayName} <img src="/assets/icons/verified.svg" />
            </h1>
            <XChips chips={["CEO at Almas Entertainment"]} />
            <XTitles items={itemsXTitles} />
            {/* {artist.socialMedia && (
              <XTitle
                label="Social Media"
                value={<XSocials socialMedia={artist.socialMedia} />}
              />
            )} */}
          </div>
          <div className="flex flex-col flex-start gap-3">
            <XFButton title="Book Now" type="button" size="lg" width={210} />
            <XFButton
              title="Follow"
              type="button"
              size="lg"
              width={210}
              variant="secondary"
            />
          </div>
        </div>

        <XSpace />
        <XSpace />

        <h3>Highlights</h3>
        <XHighlights items={highlights} />
        <XSpace />
        <XHighlights items={highlights2} />

        <XSpace />
        <XSpace />

        <h3>Upcoming Events</h3>
        <XItemEvents
          items={[
            testEventListItem1,
            testEventListItem2,
            testEventListItem3,
            testEventListItem1,
            testEventListItem2,
            testEventListItem3,
          ]}
        />

        <XSpace />
        <XSpace />

        <h3>Services</h3>
        <XTabsVertical items={services} />

        <XSpace />
        <XSpace />

        <h3>Biography</h3>
        <p className="text-md">{artist.description}</p>

        <XSpace />
        <XSpace />

        <h3>Social Media</h3>
        <XHighlights items={highlights2} />
        <XSpace />
        <XHighlights items={highlights2} />
        <XSpace />
        <XHighlights items={highlights2} />
        <XSpace />
        <XHighlights items={highlights2} />
      </Page>
    );
  } else {
    return <></>;
  }
};

export default ArtistDetailsPage;
