import { Box } from "@mui/material";

interface IXSpaceProps {
  height?: number;
}

export const XSpace = (props: IXSpaceProps) => {
  const sx = {
    height: props.height || 40,
  };
  return <Box sx={sx}></Box>;
};
