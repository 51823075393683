import {
  EntityType,
  CreateChatInput,
  CreateEventInput,
  CreateVendorInput,
  Event,
  SponsorStatus,
  Vendor,
  VendorType,
  CreateHighlightInput,
  CreateEndorsementInput,
  SocialMediaType,
} from "shared/API";

const time: number = new Date("2223-11-20").getTime();

export const testEvent1: CreateEventInput = {
  id: "patooeventproduction",
  title: "Patoo Birthday Bash",
  description:
    "This is Patoo's 30th birthday. Come down to luxurious venue in LA to celebrate him.",
  startTimestamp: time,
  endTimestamp: time,
  address: {
    street1: "10450 EB Ave",
    city: "San Francisco",
    state: "CA",
    country: "USA",
    postalCode: "90023",
  },
  location: {
    latitude: 37.773972,
    longitude: -122.431297,
  },

  ticketLink: "http://eventbrite.com/patoobithday-ticket-link",
  contact: { emails: ["patoo@neatcodes.com"], phones: ["+13104869655"] },
};

export const testEvent2: CreateEventInput = {
  id: "gorjieventproduction",
  title: "UCLA Nowruz Celebration",
  description: "Let's cherish our culture and celebrate Nowruz with Kamyar",
  startTimestamp: time,
  endTimestamp: time,
  address: {
    street1: "10450 EB Ave",
    city: "Los Angeles",
    state: "CA",
    country: "USA",
    postalCode: "90023",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  ticketLink: "https://eventbrite.com/uci2023-nowruz",
  contact: { emails: ["isg@ucla.com"], phones: ["+989125128686"] },
};

export const testVendor1: CreateVendorInput = {
  id: "deejaygolbarg",
  username: "deejaygolbarg",
  displayName: "Deejay Rahimi",
  headline: "International DJ",
  description: "I'm the best deejay that you could hire for your festival.",
  address: {
    street1: "10450 East B",
    city: "Los Angeles",
    state: "CA",
    country: "US",
    postalCode: "90025",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  contact: { emails: ["deejayaaraa@gmail.com"], phones: ["+19496775896"] },
  audience: ["EN"],
  types: [VendorType.Deejay],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "https://deejayaaraa.com",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "https://facebook.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "https://instagram.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Spotify,
      url: "https://spotify.com/deejayaaraa",
    },
  ],
};

export const testChatVendor: CreateVendorInput = {
  id: "deejayjoozi",
  username: "deejayjoozi",
  displayName: "Deejay Joozi",
  headline: "International DJ",
  description: "I'm the best deejay that you could hire for your festival.",
  address: {
    street1: "10450 East B",
    city: "Los Angeles",
    state: "CA",
    country: "US",
    postalCode: "90025",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  contact: { emails: ["deejayaaraa@gmail.com"], phones: ["+19496775896"] },
  audience: ["EN"],
  types: [VendorType.Deejay],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "https://deejayaaraa.com",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "https://facebook.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "https://instagram.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Spotify,
      url: "https://spotify.com/deejayaaraa",
    },
  ],
};

export const testVendor2: CreateVendorInput = {
  id: "almasentertainment",
  username: "almasentertainment",
  displayName: "Almas Entertainment",
  headline: "Sound and Light Vendor",
  types: [VendorType.PoweredSpeaker],
  description: "Let us take care of your events no matter where the U.S.",
  address: {
    street1: "1020 Jeffry",
    city: "San Francisco",
    state: "CA",
    country: "US",
    postalCode: "92618",
  },
  location: {
    latitude: 37.773972,
    longitude: -122.431297,
  },
  contact: { emails: ["contact@entertainment.com"], phones: ["+19597684490"] },
  audience: ["FA", "EN"],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "http://almas.com",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "http://www.facebook.com/almasenterntainment",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "http://www.instagram.com/almasenterntainment",
    },
    {
      socialMediaType: SocialMediaType.SoundCloud,
      url: "http://www.soundcloud.com/almasenterntainment",
    },
  ],
};

export const testChatSpace: CreateChatInput = {
  id: "testChat",
  title: "Wedding at Pelican Hills on May 20th",
  client: { memberId: "", type: EntityType.User },
  provider: { memberId: "", type: EntityType.Vendor },
};
export const testEventListItem1: Event = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "patooeventproduction",
  title: "Kamran & Hooman Live in Las Vegas",
  description:
    "This is Patoo's 30th birthday. Come down to luxurious venue in LA to celebrate him. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  startTimestamp: new Date("2024-09-28").getTime(),
  endTimestamp: time,
  address: {
    street1: "10450 EB Ave",
    city: "Las Vegas",
    state: "NV",
    country: "USA",
    postalCode: "90023",
  },
  location: {
    latitude: 37.773972,
    longitude: -122.431297,
  },
  ticketLink: "http://eventbrite.com/patoobithday-ticket-link",
  contact: { emails: ["patoo@neatcodes.com"], phones: ["+13104869655"] },
  imageUrl: "/images/event-kh.jpeg",
  hosts: [
    {
      id: "deejayAl",
      displayName: "Deejay AL",
      type: EntityType.Vendor,
    },
    {
      id: "ambyx",
      displayName: "Ambyx Productions",
      type: EntityType.Vendor,
    },
  ],
};

export const testEventListItem2: Event = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "gorjieventproduction",
  title: "Moein Live in Los Angeles",
  description: "Let's cherish our culture and celebrate Nowruz with Kamyar",
  startTimestamp: new Date("2024-09-18").getTime(),
  endTimestamp: time,
  address: {
    street1: "10450 EB Ave",
    city: "Los Angeles",
    state: "CA",
    country: "USA",
    postalCode: "90023",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  ticketLink: "https://eventbrite.com/uci2023-nowruz",
  contact: { emails: ["isg@ucla.com"], phones: ["+989125128686"] },
  imageUrl: "/images/event-moein.jpeg",
};

export const testEventListItem3: Event = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "gorjieventproduction",
  title: "Sirvan Live in Vancouver",
  description: "Let's cherish our culture and celebrate Nowruz with Kamyar",
  startTimestamp: time,
  endTimestamp: new Date("2024-08-29").getTime(),
  address: {
    street1: "10450 EB Ave",
    city: "Vancouver",
    state: "BC",
    country: "USA",
    postalCode: "90023",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  ticketLink: "https://eventbrite.com/uci2023-nowruz",
  contact: { emails: ["isg@ucla.com"], phones: ["+989125128686"] },
  imageUrl: "/images/event-sirvan.jpeg",
};

export const testEventListItem4: Event = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "gorjieventproduction",
  title: "Ebi Live in Seattle",
  description: "Let's cherish our culture and celebrate Nowruz with Kamyar",
  startTimestamp: new Date("2024-08-20").getTime(),
  endTimestamp: time,
  address: {
    street1: "10450 EB Ave",
    city: "Seattle",
    state: "WA",
    country: "USA",
    postalCode: "90023",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  ticketLink: "https://eventbrite.com/uci2023-nowruz",
  contact: { emails: ["isg@ucla.com"], phones: ["+989125128686"] },
  imageUrl: "/images/event-ebi.jpeg",
};

export const testVendorListItem1: Vendor = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "deejayAl",
  username: "deejaygolbarg",
  displayName: "Deejay AL",
  headline: "International DJ",
  description:
    "Deejay AL is one of the few and true practitioners of Open-Format DJing. Spinning at some of the hottest nightclubs in the world, he strives to make the crowd have an unforgettable time while performing. \n What sets him apart is his versatility, from memorable event hosting to providing unforgettable energy. \n One day he’s spinning international music to thousands of people at various large- scale events.",
  address: {
    street1: "10450 East B",
    city: "Los Angeles",
    state: "CA",
    country: "US",
    postalCode: "90025",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  contact: { emails: ["deejayaaraa@gmail.com"], phones: ["+19496775896"] },
  audience: ["EN"],
  types: [VendorType.Deejay],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "https://deejayal.com",
    },
    {
      socialMediaType: SocialMediaType.Youtube,
      url: "https://www.youtube.com/channel/UCGEhv3rJ4UOWF7W2a_E8Nzw",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "https://instagram.com/deejayal",
    },
    {
      socialMediaType: SocialMediaType.Spotify,
      url: "https://spotify.com/deejayal",
    },
    {
      socialMediaType: SocialMediaType.SoundCloud,
      url: "https://soundcloud.com/deejayal",
    },
    {
      socialMediaType: SocialMediaType.Twitter,
      url: "https://twitter.com/deejayal",
    },
    {
      socialMediaType: SocialMediaType.Telegram,
      url: "https://t.me/deejayal",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "https://www.facebook.com/deejayal",
    },
  ],
  imageUrl: "/images/vendor-al.jpeg",
};

export const testVendorListItem2: Vendor = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "deejaygolbarg",
  username: "deejaygolbarg",
  displayName: "Kamyar",
  headline: "International Singer",
  description: "I'm the best deejay that you could hire for your festival.",
  address: {
    street1: "10450 East B",
    city: "Los Angeles",
    state: "CA",
    country: "US",
    postalCode: "90025",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  contact: { emails: ["deejayaaraa@gmail.com"], phones: ["+19496775896"] },
  audience: ["EN"],
  types: [VendorType.Deejay],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "https://deejayaaraa.com",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "https://facebook.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "https://instagram.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Spotify,
      url: "https://spotify.com/deejayaaraa",
    },
  ],
  imageUrl: "/images/vendor-kamyar.jpeg",
};

export const testVendorListItem3: Vendor = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "deejaygolbarg",
  username: "deejaygolbarg",
  displayName: "Andy",
  headline: "International DJ",
  description: "I'm the best deejay that you could hire for your festival.",
  address: {
    street1: "10450 East B",
    city: "Los Angeles",
    state: "CA",
    country: "US",
    postalCode: "90025",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  contact: { emails: ["deejayaaraa@gmail.com"], phones: ["+19496775896"] },
  audience: ["EN"],
  types: [VendorType.Deejay],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "https://deejayaaraa.com",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "https://facebook.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "https://instagram.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Spotify,
      url: "https://spotify.com/deejayaaraa",
    },
  ],
  imageUrl: "/images/vendor-andy.jpeg",
};

export const testVendorListItem4: Vendor = {
  sponsorStatus: SponsorStatus.Gold,
  createdAt: 0,
  ownerId: "mahdiOwner",
  id: "deejaygolbarg",
  username: "deejaygolbarg",
  displayName: "Sara Naeini",
  headline: "Singer",
  description: "I'm the best deejay that you could hire for your festival.",
  address: {
    street1: "10450 East B",
    city: "Los Angeles",
    state: "CA",
    country: "US",
    postalCode: "90025",
  },
  location: {
    latitude: 34.052235,
    longitude: -118.243683,
  },
  contact: { emails: ["deejayaaraa@gmail.com"], phones: ["+19496775896"] },
  audience: ["EN"],
  types: [VendorType.Deejay],
  socialMedia: [
    {
      socialMediaType: SocialMediaType.Website,
      url: "https://deejayaaraa.com",
    },
    {
      socialMediaType: SocialMediaType.Facebook,
      url: "https://facebook.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Instagram,
      url: "https://instagram.com/deejayaaraa",
    },
    {
      socialMediaType: SocialMediaType.Spotify,
      url: "https://spotify.com/deejayaaraa",
    },
  ],
  imageUrl: "/images/vendor-sara.jpeg",
};

export const testHighlight1: CreateHighlightInput = {
  id: "newsong",
  title: "One Night in Dubai",
  subtitle: "Spotify",
  order: 1,
  url: "https://open.spotify.com/track/0AmwmAEOgm11kXiaL9jHOZ",
};

export const testHighlight2: CreateHighlightInput = {
  id: "newpost",
  title: "Wedding in Dubai",
  subtitle: "Instagram",
  order: 2,
  url: "https://www.instagram.com/p/C82TKpQvX1k/?hl=en&img_index=1",
};

export const testEndorsement1: CreateEndorsementInput = {
  id: "newendorsement",
  endorser: {
    id: "almasentertainment",
    displayName: "Almas Production",
    type: EntityType.Vendor,
    imageUrl: "https://www.instagram.com/almas.rec/?hl=en",
  },
};
