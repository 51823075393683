import {
  isEmailValidRegex,
  isPhoneNumberRegex,
  isUrlValidRegex,
} from "shared/service/utils/regex";
import * as yup from "yup";

export const schemaRegister = yup.object().shape({
  email: yup.string().email(),
  password: yup.string(),
  confirmPassword: yup.string(),
});

export const schemaArtistCreate = yup.object().shape({
  username: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Username is required"),
  displayName: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Display Name is required"),
  headline: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Headline is required"),
});

export const schemaArtistUpdate = yup.object().shape({
  username: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Username is required"),
  displayName: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Display Name is required"),
  headline: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Headline is required"),
  description: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Description is required"),
  contactEmail: yup
    .string()
    .matches(isEmailValidRegex, "Email is not valid")
    .required("Email is required"),
  contactPhone: yup
    .string()
    .matches(isPhoneNumberRegex, "Phone number is not valid")
    .required("Phone number is required"),
  website: yup.string().matches(isUrlValidRegex, "Please enter a valid URL"),
});

export const schemaEventCreate = yup.object().shape({
  title: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Required"),
  ticketLink: yup.string().matches(isUrlValidRegex, "Please enter a valid URL"),
  description: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Description is required"),
  startTimestamp: yup
    .date()
    .min(new Date(), "Start time must be in the future")
    .required("Date is required"),
  endTimestamp: yup
    .date()
    .min(yup.ref("startDate"), "End time must be later than start time"),
  contactEmail: yup
    .string()
    .matches(isEmailValidRegex, "Email is not valid")
    .required("Email is required"),
  contactPhone: yup
    .string()
    .matches(isPhoneNumberRegex, "Phone number is not valid")
    .required("Phone number is required"),
});

export const schemaLogin = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup.string().required("Required"),
});
