import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

const white = "#FFFFFF";

// const fontFamily = `"Poppins", "Google Sans", "Roboto", "Helvetica", "Arial", "sans-serif"`;

const theme = createTheme({
  spacing: 8,
  typography: {
    h1: {
      fontFamily: "Poppins",
      fontSize: "40px",
      fontWeight: "250",
      lineHeight: "72px",
      color: white,
      margin: "16px 0px",
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: "40px",
      fontWeight: 300,
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: 20,
    },
    body2: {
      fontFamily: "Poppins",
      fontWeight: 16,
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#000000",
      paper: "#000000",
    },
    primary: {
      contrastText: white,
      dark: colors.grey[700],
      main: colors.grey[800],
      light: colors.grey[100],
    },
    secondary: {
      contrastText: white,
      dark: colors.blueGrey[900],
      main: colors.blueGrey[900],
      light: colors.blueGrey[800],
    },
    success: {
      contrastText: white,
      dark: colors.blueGrey[900],
      main: colors.blueGrey[900],
      light: colors.blueGrey[800],
    },
    info: {
      contrastText: white,
      dark: colors.blueGrey[900],
      main: colors.blueGrey[900],
      light: colors.blueGrey[800],
    },
    warning: {
      contrastText: white,
      dark: colors.blueGrey[900],
      main: colors.blueGrey[900],
      light: colors.blueGrey[800],
    },
    error: {
      contrastText: white,
      dark: colors.blueGrey[900],
      main: colors.blueGrey[900],
      light: colors.blueGrey[800],
    },
    text: {
      primary: white,
      secondary: white,
    },
    divider: colors.blueGrey[900],
  },
});

export default theme;
