import { XIconed } from "components/common/XIconed";
import { iLocation } from "components/common/XIcons";
import { Event } from "shared/API";
import { extractLocation } from "utils/address";
import { truncateTooltip } from "utils/formatter/truncate";
import { XList } from "./cards/XList";
import { XFButton } from "./forms";
import { Box } from "@mui/material";

interface XItemEventProps {
  title: string;
  to?: string;
  imageURL?: string;
  location?: string;
  date?: Date;
  ticketLink?: string;
}

interface XItemEventsProps {
  items: Event[];
}

export const XItemEvents = (props: XItemEventsProps) => {
  return (
    <XList>
      {props.items.map((x, i) => (
        <XItemEvent
          key={i}
          title={x.title}
          date={new Date(x.startTimestamp)}
          imageURL={x.imageUrl}
          location={x.address && extractLocation(x.address)}
          ticketLink={x.ticketLink}
        />
      ))}
    </XList>
  );
};

export const XItemEvent = (props: XItemEventProps) => {
  return (
    <div className="flex flex-col p-2 gap-3">
      <Box
        className="flex items-center w-[222px] max-w-[222px] h-[298px] bg-center bg-cover rounded-[10px] relative"
        sx={{
          backgroundImage: `url(${props.imageURL})`,
          "& .book": {
            display: "none !important",
          },
          "&:hover .book": {
            display: "flex !important",
          },
        }}
      >
        {props.date && (
          <div className="flex flex-col w-[55px] h-[63px] text-center uppercase bg-white rounded-[10px] absolute top-[-8px] left-[-8px] z-10 items-center justify-center">
            <div className="text-primary text-sm">
              {props.date.toLocaleString("default", { month: "short" })}
            </div>
            <div className="text-black text-lg leading-[24px]">
              {props.date.toLocaleString("default", { day: "2-digit" })}
            </div>
          </div>
        )}
        <div
          className="w-full flex justify-end book flex-col gap-4 p-6 h-[100%]"
          style={{
            background:
              "linear-gradient(180deg, rgba(144,148,149,0) 0%, rgba(0,0,0,1) 75%)",
          }}
        >
          {props.ticketLink && (
            <XFButton title="Buy Tickets" size="md" width="100%" />
          )}
          <XFButton
            title="Learn More"
            size="md"
            width="100%"
            variant="secondary"
          />
        </div>
      </Box>
      <div className="text-sm font-medium">
        {truncateTooltip(props.title, 19)}
      </div>
      {props.location && (
        <div className="text-sm text-white/70">
          <XIconed icon={iLocation} text={props.location} />
        </div>
      )}
    </div>
  );
};
