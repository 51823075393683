import { Skeleton } from "@mui/material";
import XEventRow from "components/cards/XEventRow";
import Page from "components/Page";
import { useEffect, useState } from "react";
import { Event } from "shared/API";
import { eventsAPI } from "shared/service/Event";

const EventsPage = () => {
  const [events, setEvents] = useState<Array<Event>>([]);
  // TODO MR: Fix loading skeleton as well as handling errors
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [errors, setErrors] = useState<Array<{string}>>([]);

  const getEvents = async () => {
    // loading mode starts
    try {
      const data = await eventsAPI({
        query: { limit: 10 },
      });
      setEvents(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      // loading mode completes
    }
  };
  useEffect(() => {
    getEvents();
  }, []);

  if (events) {
    return (
      <Page title="Events">
        <XEventRow events={events} />
      </Page>
    );
  } else {
    return <Skeleton />;
  }
};

export default EventsPage;
