import Page from "components/Page";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Vendor } from "shared/API";
import { publishVendorAPI, vendorsAPI } from "shared/service/Vendor";
import {
  Edit,
  MoreHorizRounded,
  Publish,
  Visibility,
} from "@mui/icons-material";
import useAuth from "hooks/useAuth";
import { useMutation } from "@tanstack/react-query";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import XTable from "components/common/XTable";
import routes from "router/routes";

const MyArtistsPage = () => {
  const { userId } = useAuth();
  const [artists, setArtists] = useState<Array<Vendor>>();
  // TODO: implement errors for query
  // const [errors, setErrors] = useState<Array<{string}>>([]);

  const getArtists = async (userId: string) => {
    // loading mode starts
    try {
      const data = await vendorsAPI({
        query: {
          queryInput: {
            andStatements: [
              {
                whereFilters: [
                  {
                    field: "ownerId",
                    operation: "==",
                    value: userId,
                  },
                ],
              },
            ],
          },
          limit: 10,
        },
        isPrivate: true,
      });
      setArtists(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      // loading mode completes
    }
  };

  useEffect(() => {
    if (userId) getArtists(userId);
  }, [userId]);

  const { mutate: publishVendor } = useMutation({
    mutationKey: ["EventMutationPage.publishVendor"],
    mutationFn: publishVendorAPI,
  });

  const handlePublish = (id: string) => {
    publishVendor({ vendorId: id });
  };

  const rows =
    artists?.map((x) => ({
      id: x.id,
      displayName: x.displayName,
      createdAt: x.createdAt,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Display Name",
      sortable: true,
      minWidth: 240,
    },
    { field: "id", headerName: "Artist ID", minWidth: 240 },
    { field: "createdAt", headerName: "Created At", minWidth: 200 },
    {
      field: "actions",
      headerName: "",
      minWidth: 200,
      align: "right",
      renderCell: (params) => (
        <div className="flex justify-end gap-2">
          <Link to={routes.MyArtists.details(params.row.id).path}>
            <IconButton aria-label="view">
              <Visibility />
            </IconButton>
          </Link>
          <Link to={routes.MyArtists.edit(params.row.id).path}>
            <IconButton aria-label="edit">
              <Edit />
            </IconButton>
          </Link>
          <IconButton aria-label="edit">
            <MoreHorizRounded />
          </IconButton>
          <IconButton aria-label="Publish">
            <Publish onClick={() => handlePublish(params.row.id)} />
          </IconButton>
        </div>
      ),
      flex: 1,
    },
  ];

  return (
    <Page title="My Artists">
      <XTable rows={rows} columns={columns} />
    </Page>
  );
};

export default MyArtistsPage;
