enum DatabaseStatus {
  NotFound = "iplanx/not-found",
  Success = "iplanx/successful",
  Failure = "iplanx/failure",
  UnAuthorizedAccess = "iplanx/unauthorized-access",
}

enum InputStatus {
  Valid = "iplanx/valid",
  InvalidId = "iplanx/invalid-id",
}

// Authentication Errors can be found at: https://firebase.google.com/docs/auth/admin/errors
enum UserStatus {
  NoSignedInUser = "iplanx/no-signed-in-user",
  InvalidUsernameOrPassword = "iplanx/invalid-username-or-password",
  InvalidDate = "iplanx/invalid-date",
  InvalidGender = "iplanx/invalid-gender",
}

enum EventStatus {
  InvalidContact = "iplanx/invalid-contact",
  InvalidTitle = "iplanx/invalid-title",
  InvalidTicketLink = "iplanx/invalid-ticket-link",
  InvalidDescription = "iplanx/invalid-description",
  InvalidStartTime = "iplanx/invalid-start-time",
  InvalidEndTime = "iplanx/invalid-end-time",
}

enum SocialMediaStatus {
  InvalidWebsite = "iplanx/invalid-website",
  InvalidFacebook = "iplanx/invalid-facebook",
  InvalidInstagram = "iplanx/invalid-instagram",
  InvalidYoutube = "iplanx/invalid-youtube",
  InvalidTikTok = "iplanx/invalid-tiktok",
  InvalidTwitter = "iplanx/invalid-twitter",
  InvalidTelegram = "iplanx/invalid-telegram",
  InvalidSoundCloud = "iplanx/invalid-sound-cloud",
  InvalidSpotify = "iplanx/invalid-spotify",
  InvalidAppleMusic = "iplanx/invalid-apple-music",
  InvalidWhatsapp = "iplanx/invalid-whatsapp",
  InvalidOnlyFans = "iplanx/invalid-only-fans",
  InvalidAmazonMusic = "iplanx/invalid-amazon-music",
  InvalidPinterest = "iplanx/invalid-pinterest",
  InvalidYelp = "iplanx/invalid-yelp",
  InvalidMessenger = "iplanx/invalid-messenger",
  InvalidLinkedIn = "iplanx/invalid-linkedin",
  InvalidGoogle = "iplanx/invalid-google",
  InvalidSnapchat = "iplanx/invalid-snapchat",
  InvalidTwitch = "iplanx/invalid-twitch",
  InvalidOther = "iplanx/invalid-other",
}

enum VendorStatus {
  InvalidUsername = "iplanx/invalid-username",
  InvalidContact = "iplanx/invalid-contact",
  InvalidDescription = "iplanx/invalid-description",
  InvalidName = "iplanx/invalid-name",
  InvalidHeadline = "iplanx/invalid-headline",
  InvalidAddress = "iplanx/invalid-address",
}

enum ChatStatus {
  InvalidParticipant = "iplanx/invalid-participant",
}

// Storage errors can be found at: https://firebase.google.com/docs/storage/web/handle-errors
enum StorageStatus {
  InvalidType = "iplanx/invalid-type",
  InvalidText = "iplanx/invalid-text",
  InvalidSenderId = "iplanx/invalid-sender-id",
}

enum NotificationStatus {
  InvalidLink = "iplanx/invalid-link",
  InvalidTitle = "iplanx/invalid-title",
  InvalidDescription = "iplanx/invalid-description",
  InvalidRecipientId = "iplanx/invalid-recipient-id",
  InvalidSenderId = "iplanx/invalid-sender-id",
}

export type XStatus =
  | UserStatus
  | EventStatus
  | DatabaseStatus
  | InputStatus
  | StorageStatus
  | VendorStatus
  | SocialMediaStatus
  | ChatStatus
  | NotificationStatus;
export const XStatus = {
  ...UserStatus,
  ...DatabaseStatus,
  ...EventStatus,
  ...InputStatus,
  ...StorageStatus,
  ...VendorStatus,
  ...SocialMediaStatus,
  ...ChatStatus,
  ...NotificationStatus,
};
