import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { XFButton } from "./forms";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface XTabsVerticalProps {
  items: {
    type: string;
    description: string;
    icon: string;
    href: string;
  }[];
}

export const XTabsVertical = (props: XTabsVerticalProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        display: "flex",
        height: 400,
        "& .MuiButtonBase-root": {
          fontSize: 20,
          opacity: 0.3,
          px: 4,
          py: 2,
          "& img": {
            width: 60,
          },
        },
        "& .Mui-selected": {
          color: "#FFFFFF !important",
          borderRight: 0,
          fontWeight: 500,
          fontSize: 32,
          opacity: 1,
          borderRadius: "50px",
          bgcolor: "rgba(255, 58, 63, 0.2)",
          textTransform: "capitalize",
          "& img": {
            width: 84,
          },
        },
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className="overflow-visible"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        {props.items.map((x, i) => (
          <Tab
            className="w-[350px] flex flex-row justify-start gap-2"
            iconPosition="start"
            icon={<img src={x.icon} width="30" style={{ fill: "#ff0000" }} />}
            label={x.type}
            key={i}
            {...a11yProps(i)}
          />
        ))}
      </Tabs>
      {props.items.map((x, i) => (
        <div
          key={i}
          role="tabpanel"
          hidden={value !== i}
          id={`vertical-tabpanel-${i}`}
          aria-labelledby={`vertical-tab-${i}`}
          className="flex items-center"
        >
          {value === i && (
            <div className="flex flex-col p-8 gap-4">
              <span className="text-md">{x.description}</span>
              <XFButton title="Learn More" size="sm" variant="secondary" />
            </div>
          )}
        </div>
      ))}
    </Box>
  );
};
