import { Box, Typography } from "@mui/joy";
import { SxProps } from "@mui/material";
import { XIconed } from "components/common/XIconed";
import { iArrow, iLocation, iTimeCircle } from "components/common/XIcons";
import { XLine } from "components/common/XLine";
import { XTag } from "components/common/XTag";
import { NavLink } from "react-router-dom";
import routes from "router/routes";
import { Event } from "shared/API";

interface IXEventRowProps {
  events: Array<Event>;
}

const XEventRow = (props: IXEventRowProps) => {
  const sxEventRow: SxProps = {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    gap: 3,
    width: "100%",
    textDecoration: "none !important",
    ":hover": {
      opacity: 0.9,
    },
  };

  const sxEventInfoContainer: SxProps = {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    background: "#161616",
    color: "#ffffff",
    p: 1.5,
    gap: 1,
  };
  const sxEventInfo: SxProps = {
    flex: 1,
    px: 2,
    py: 1.5,
  };
  const sxEventDetails: SxProps = {
    display: "flex",
    mt: 2,
  };

  const sxTitle: SxProps = {
    fontSize: 20,
    fontWeight: 600,
    textDecoration: "none",
    color: "#ffffff",
  };

  const sxEventTags: SxProps = {
    display: "flex",
    flex: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: 1,
  };

  const sxEventAction: SxProps = {
    display: "flex",
    verticalAlign: "middle",
    alignItems: "center",
    background: "#000000",
    "& button": {
      p: 0,
      minWidth: 0,
      color: "#ffffff",
    },
    "& img": {
      width: "8px",
    },
    "& a": {
      fontSize: 0,
      px: 2,
    },
    "& a:hover": {
      opacity: 0.95,
    },
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {props.events?.map((x, i) => {
        if (x) {
          const date = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(x.startTimestamp);

          const sxEventImage: SxProps = {
            position: "relative",
            width: 140,
            height: 140,
            backgroundImage: `url(${x.imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            "& span": {
              position: "absolute",
              background: "rgba(255, 255, 255, 0.7)",
              top: 10,
              right: 10,
              borderRadius: 2,
              zIndex: 10,
              fontSize: 8,
              fontWeight: 600,
              textTransform: "uppercase",
              py: 0.25,
              px: 0.75,
            },
          };

          return (
            <Box sx={sxEventRow} key={i}>
              <Box sx={sxEventImage}></Box>
              <Box sx={sxEventInfoContainer}>
                <Box sx={sxEventInfo}>
                  <Typography level="h4" sx={sxTitle}>
                    {x.title}
                  </Typography>
                  <Box sx={sxEventDetails}>
                    <XIconed icon={iTimeCircle} text={date} />
                    <XLine />
                    <XIconed
                      icon={iLocation}
                      text={`${x.address?.city}, ${x.address?.state}`}
                    />
                  </Box>
                </Box>
                <Box sx={sxEventTags}>
                  <XTag text={"Guestlist"} isFilled={true} />
                  <XTag text={"Tables"} isFilled={true} />
                  <XTag text={"Tickets"} />
                </Box>
                <Box sx={sxEventAction}>
                  <NavLink to={routes.Events.details().path}>
                    <img src={iArrow} />
                  </NavLink>
                </Box>
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default XEventRow;
