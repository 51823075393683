import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "styles/theme";
import { Router } from "./router";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { initializeFirebase } from "shared/Firebase";

const App: React.FunctionComponent = () => {
  initializeFirebase(true);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="flex flex-col min-h-[100vh] bg-[#000000]">
          <Header />
          <main className="w-full flex-1 mx-auto py-9 px-6 overflow-y-scroll max-w-[1248px] scrollbar-tailwind">
            <Router />
          </main>
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
