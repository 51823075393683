import XFRow from "./XFRow";
import XFTextField from "./XFTextField";

const XFContactFields = () => {
  return (
    <XFRow>
      <XFTextField label="Phone Number" name="contactPhone" />
      <XFTextField label="Email" name="contactEmail" />
    </XFRow>
  );
};

export default XFContactFields;
