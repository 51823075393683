import Page from "components/Page";
import { Box, Typography } from "@mui/joy";
import { SxProps } from "@mui/material";
import { Event } from "shared/API";
import { XIconed } from "components/common/XIconed";
import {
  iCalendar,
  iEarth,
  iLocation,
  iTimeCircle,
  iUsers,
} from "components/common/XIcons";
import { XSpace } from "components/common/XSpace";
import { testEventListItem1 } from "shared/service/__test__/TestData";
import XButton from "components/common/XButton";

const EventDetailsPage = () => {
  const event: Event = testEventListItem1;

  const sxEventDetailsContainer: SxProps = {
    display: "flex",
    gap: 10,
    width: "100%",
  };

  const sxEventDetails: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    fontSize: "20px",
    fontWeight: 300,
    color: "#ffffff",
  };

  const sxH1: SxProps = {
    fontSize: 40,
    fontWeight: 600,
    color: "#ffffff",
    m: 0,
  };

  const sxDivider: SxProps = {
    background:
      "linear-gradient(90deg, rgba(204, 204, 204, 0.2) 0%, rgba(204, 204, 204, 0) 60%)",
    height: "1px",
  };

  const sxDateTime: SxProps = {
    display: "flex",
    gap: 12,
  };

  const sxList: SxProps = {
    display: "flex",
    gap: 4,
  };

  const sxEventImage: SxProps = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: 400,
    height: 400,
  };

  return (
    <Page>
      <XSpace />
      <Box sx={sxEventDetailsContainer}>
        <Box sx={sxEventImage}></Box>
        <Box sx={sxEventDetails}>
          <Typography level="h1" sx={sxH1}>
            {event.title}
          </Typography>
          <XIconed
            icon={iLocation}
            text={`${event.address?.city}, ${event.address?.state}`}
            size="lg"
          />
          <Box sx={sxDivider}></Box>
          <Box sx={sxDateTime}>
            <XIconed icon={iCalendar} text="Jan 27th, 2024" size="md" />
            <XIconed icon={iTimeCircle} text="7:00 PM" size="md" />
          </Box>
          <Box sx={sxDivider}></Box>
          <XIconed
            icon={iUsers}
            text="Artists"
            opts={{ uppercase: true }}
            size="md"
          >
            <Box sx={sxList}>
              <Box>Kamran</Box>
              <Box>Hooman</Box>
              <Box>Deejay AL</Box>
            </Box>
          </XIconed>
          {event.hosts && (
            <>
              <XIconed
                icon={iEarth}
                text="Hosts"
                opts={{ uppercase: true }}
                size="md"
              >
                <Box sx={sxList}>
                  {event.hosts.map((x, i) => {
                    return <Box key={i}>{x.displayName}</Box>;
                  })}
                </Box>
              </XIconed>
            </>
          )}
        </Box>
      </Box>
      <XSpace />
      <Typography level="h1">About Event</Typography>
      <p>{event.description}</p>
      <XSpace />
      <p style={{ textAlign: "center" }}>
        <XButton to="https://eventbrite.com">Buy Tickets</XButton>
      </p>
    </Page>
  );
};

export default EventDetailsPage;
