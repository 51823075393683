import { Chip } from "@mui/material";

interface XChipsProps {
  chips: string[];
}

const XChips = (props: XChipsProps) => {
  return (
    <div className="flex gap-2">
      {props.chips.map((x, i) => (
        <Chip label={x} key={i} className="bg-[#ffffff]/10" />
      ))}
    </div>
  );
};

export default XChips;
