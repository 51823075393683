import { Link } from "react-router-dom";

interface XHighlightsProps {
  items: {
    title: string;
    subtitle: string;
    href: string;
    imageUrl: string;
  }[];
}

export const XHighlights = (props: XHighlightsProps) => {
  return (
    <div className="flex gap-4">
      {props.items.map((x, i) => (
        <Link
          to={x.href}
          key={i}
          className="flex flex-1 gap-3 rounded-[84px] bg-gray1/20 p-4 pr-[30px] hover:bg-primary/20 transition-all duration-500 transform"
          target="_blank"
        >
          <img src={x.imageUrl} className="w-[86px] h-[86px] rounded-[100%]" />
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-md">{x.title}</div>
            <div className="text-xxs text-white/70 font-light">
              {x.subtitle}
            </div>
          </div>
          <img src="/assets/icons/link.svg" className="w-[20px]" />
        </Link>
      ))}
    </div>
  );
};
