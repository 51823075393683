import Tooltip from "@mui/material/Tooltip";

export const truncateString = (str: string, maxLength: number = 40): string => {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
};

export const truncateTooltip = (
  str: string,
  maxLength: number = 40
): React.ReactNode => {
  if (maxLength && str.length > maxLength) {
    return (
      <Tooltip title={str}>
        <>{truncateString(str, maxLength)}</>
      </Tooltip>
    );
  } else {
    return str;
  }
};
