import XFRow from "./XFRow";
import XFTextField from "./XFTextField";

const XFSocialMediaFields = () => {
  return (
    <>
      <XFRow>
        <XFTextField name="instagram" label="Instargarm" />
        <XFTextField name="tikTok" label="Tiktok" />
      </XFRow>
      <XFRow>
        <XFTextField name="twitter" label="Twitter" />
        <XFTextField name="telegram" label="Telegram" />
      </XFRow>
      <XFRow>
        <XFTextField name="youtube" label="Youtube" />
        <XFTextField name="facebook" label="Facebook" />
      </XFRow>
      <XFRow>
        <XFTextField name="whatsapp" label="Whatsapp" />
        <XFTextField name="website" label="Website" />
      </XFRow>
      <XFRow>
        <XFTextField name="soundCloud" label="Sound Cloud" />
        <XFTextField name="spotify" label="Spotify" />
      </XFRow>
      <XFRow>
        <XFTextField name="appleMusic" label="Apple Music" />
        <XFTextField name="onlyFans" label="Only Fans" />
      </XFRow>
    </>
  );
};

export default XFSocialMediaFields;
