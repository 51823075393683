export const iArrow = "/assets/icons/i-arrow.svg";
export const iCalendar = "/assets/icons/i-calendar.svg";
export const iEarth = "/assets/icons/i-earth.svg";
export const iHeart = "/assets/icons/i-heart.svg";
export const iLocation = "/assets/icons/i-location.svg";
export const iTimeCircle = "/assets/icons/i-time-circle.svg";
export const iUsers = "/assets/icons/i-users.svg";
export const iWork = "/assets/icons/i-work.svg";
export const iMail = "/assets/icons/i-mail.svg";
export const iInstagram = "/assets/icons/i-instagram.svg";
export const iYoutube = "/assets/icons/i-youtube.svg";
export const iSoundCloud = "/assets/icons/i-soundcloud.svg";
export const iTelegram = "/assets/icons/i-telegram.svg";
export const iFacebook = "/assets/icons/i-facebook.svg";
export const iTwitter = "/assets/icons/i-twitter.svg";
export const iWedding = "/assets/icons/i-wedding.svg";
export const iLight = "/assets/icons/i-light.svg";
export const iDisco = "/assets/icons/i-disco.svg";
export const iSub = "/assets/icons/i-sub.svg";
