import Page from "components/Page";
import { XFButton, XFForm, XFTextField } from "components/forms";
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { Formik, FormikValues } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import { schemaLogin } from "utils/validation/schema";

export const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState(false);

  const signInWithGoogle = async () => {
    setAuthing(true);

    signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        console.log(response);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        setAuthing(false);
      });
  };

  const handleSubmit = async (values: FormikValues, actions: any) => {
    try {
      // TODO MR: Fix
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("===> user", userCredential);
          navigate("/");
        } else {
          console.log("No user info found!");
        }
      });
    } catch (error) {
      // TODO MR: Fix
      actions.setFieldError("email", {
        message: "Invalid credentials. Please try again",
      });
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <Page title="Login">
      <Formik
        initialValues={initialValues}
        validationSchema={schemaLogin}
        onSubmit={handleSubmit}
      >
        {() => (
          <XFForm className="w-[400px]">
            <XFTextField name="email" label="Email" />
            <XFTextField name="password" type="password" label="Password" />
            <XFButton title="Login" type="submit" width="100%" size="lg" />
            <XFButton
              title="Sign In with Google"
              variant="secondary"
              onClick={() => signInWithGoogle()}
              disabled={authing}
              width="100%"
              size="lg"
              type="button"
            />
            <XFButton
              title="Sign Up with Email"
              variant="secondary"
              link={routes.Register.path}
              disabled={authing}
              width="100%"
              size="lg"
              type="button"
            />
          </XFForm>
        )}
      </Formik>
    </Page>
  );
};
