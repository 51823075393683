import {
  CreateChatInput,
  CreateEndorsementInput,
  CreateEventInput,
  CreateHighlightInput,
  CreateMessageInput,
  CreateNotificationInput,
  CreateVendorInput,
  SocialMedia,
  UpdateChatInput,
  UpdateEventInput,
  UpdateHighlightInput,
  UpdateMessageInput,
  UpdateNotificationInput,
  UpdateVendorInput,
} from "shared/API";
import {
  isAddressValid,
  isContactValid,
  isDefined,
  isStringValid,
  isTimestampValid,
  isUrlValid,
} from "shared/utils/EntityValidator";
import { XStatus } from "shared/service/utils/XStatus";

export function validateEventInput(
  eventInput: CreateEventInput | UpdateEventInput,
): XStatus {
  if (isDefined(eventInput.title) && !isStringValid(eventInput.title!)) {
    return XStatus.InvalidTitle;
  }
  if (isDefined(eventInput.ticketLink) && !isUrlValid(eventInput.ticketLink!)) {
    return XStatus.InvalidTicketLink;
  }
  if (
    isDefined(eventInput.description) &&
    !isStringValid(eventInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (
    isDefined(eventInput.startTimestamp) &&
    !isTimestampValid(eventInput.startTimestamp!)
  ) {
    return XStatus.InvalidStartTime;
  }
  if (
    isDefined(eventInput.endTimestamp) &&
    ((isDefined(eventInput.startTimestamp) &&
      eventInput.startTimestamp! > eventInput.endTimestamp!) ||
      !isTimestampValid(eventInput.endTimestamp!))
  ) {
    return XStatus.InvalidEndTime;
  }
  if (eventInput.contact && !isContactValid(eventInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(eventInput.address) && !isAddressValid(eventInput.address!)) {
    return XStatus.InvalidAddress;
  }
  return XStatus.Valid;
}

export function validateVendorInput(
  vendorInput: CreateVendorInput | UpdateVendorInput,
): XStatus {
  if (
    isDefined(vendorInput.username) &&
    !isStringValid(vendorInput.username!)
  ) {
    return XStatus.InvalidUsername;
  }
  if (
    isDefined(vendorInput.displayName) &&
    !isStringValid(vendorInput.displayName!)
  ) {
    return XStatus.InvalidName;
  }
  if (
    isDefined(vendorInput.headline) &&
    !isStringValid(vendorInput.headline!)
  ) {
    return XStatus.InvalidHeadline;
  }
  if (
    isDefined(vendorInput.description) &&
    !isStringValid(vendorInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (vendorInput.contact && !isContactValid(vendorInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(vendorInput.address) && !isAddressValid(vendorInput.address!)) {
    return XStatus.InvalidAddress;
  }
  if (isDefined(vendorInput.socialMedia)) {
    const status = isSocialMediaValid(vendorInput.socialMedia!);
    if (status != XStatus.Valid) {
      return status;
    }
  }
  return XStatus.Valid;
}

export function validateChatInput(
  chatInput: CreateChatInput | UpdateChatInput,
): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateEndorsementInput(
  endorsementInput: CreateEndorsementInput,
): XStatus {
  if (
    isDefined(endorsementInput.endorser.imageUrl) &&
    !isUrlValid(endorsementInput.endorser.imageUrl!)
  ) {
    return XStatus.InvalidLink;
  }
  if (
    isDefined(endorsementInput.endorser.displayName) &&
    !isStringValid(endorsementInput.endorser.displayName!)
  ) {
    return XStatus.InvalidName;
  }
  return XStatus.Valid;
}

export function validateHighlightInput(
  highlightInput: CreateHighlightInput | UpdateHighlightInput,
): XStatus {
  if (
    isDefined(highlightInput.title) &&
    !isStringValid(highlightInput.title!)
  ) {
    return XStatus.InvalidTitle;
  }
  if (
    isDefined(highlightInput.subtitle) &&
    !isStringValid(highlightInput.subtitle!)
  ) {
    return XStatus.InvalidTitle;
  }
  if (isDefined(highlightInput.url) && !isUrlValid(highlightInput.url!)) {
    return XStatus.InvalidLink;
  }
  return XStatus.Valid;
}

export function validateNotificationInput(
  notificationInput: CreateNotificationInput | UpdateNotificationInput,
): XStatus {
  if (
    isDefined(notificationInput.message) &&
    !isStringValid(notificationInput.message!)
  ) {
    return XStatus.InvalidText;
  }
  if (
    isDefined(notificationInput.notifier?.id) &&
    !isStringValid(notificationInput.notifier!.id)
  ) {
    return XStatus.InvalidSenderId;
  }
  if (isDefined(notificationInput.url) && !isUrlValid(notificationInput.url!)) {
    return XStatus.InvalidLink;
  }
  return XStatus.Valid;
}

export function validateCreateChatInput(chatInput: CreateChatInput): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateUpdateChatInput(chatInput: UpdateChatInput): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateCreateMessageInput(
  messageInput: CreateMessageInput,
): XStatus {
  if (isDefined(messageInput.text) && !isStringValid(messageInput.text!)) {
    return XStatus.InvalidText;
  }

  if (
    isDefined(messageInput.senderId) &&
    !isStringValid(messageInput.senderId!)
  ) {
    return XStatus.InvalidSenderId;
  }
  return XStatus.Valid;
}

export function validateUpdateMessageInput(
  messageInput: UpdateMessageInput,
): XStatus {
  if (isDefined(messageInput.text) && !isStringValid(messageInput.text!)) {
    return XStatus.InvalidText;
  }
  return XStatus.Valid;
}

export function isSocialMediaValid(socialMedia: SocialMedia[]): XStatus {
  for (const media of socialMedia) {
    if (!isUrlValid(media.url)) {
      return `iplanx/invalid-${media.socialMediaType.toLowerCase()}` as XStatus;
    }
  }
  return XStatus.Valid;
}
