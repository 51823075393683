import { NavLink } from "react-router-dom";
import routes from "router/routes";
import useAuth from "hooks/useAuth";
import AccountMenu from "./AccountMenu";

const Header = () => {
  const { userId, displayName } = useAuth();

  const initial = displayName?.charAt(0);

  return (
    <header className="w-full flex items-center justify-between mx-auto py-6 max-w-[1200px]">
      <div className="flex flex-1 items-center gap-[80px]">
        <NavLink to={routes.Home.path}>
          <img className="w-[68px] mb-3" src="/images/iPlanX-Logo-White.png" />
        </NavLink>
        <div className="flex-1 flex gap-6">
          <NavLink to={routes.Artists.list.path}>Artists</NavLink>
          <NavLink to={routes.Events.list.path}>Events</NavLink>
          {/* <NavLink to={routes.Events.details().path}>Event Details</NavLink>
          <NavLink to={routes.Vendors.details().path}>Artist Details</NavLink>
          <NavLink to={routes.Admin.Venues.path}>Admin Venues</NavLink> */}
        </div>
      </div>
      <div className="flex items-center gap-4">
        {userId ? (
          <>
            <div>Hi {displayName}</div>
            <div>
              <AccountMenu initial={initial} />
            </div>
          </>
        ) : (
          <NavLink to={routes.Login.path}>Login</NavLink>
        )}
      </div>
    </header>
  );
};

export default Header;
