import Page from "components/Page";
import { useEffect, useState } from "react";
import { Vendor } from "shared/API";
import { vendorsAPI } from "shared/service/Vendor";
import routes from "router/routes";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Edit, MoreHorizRounded, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import XTable from "components/common/XTable";

const ArtistsPage = () => {
  const [artists, setArtists] = useState<Array<Vendor>>();

  const getArtists = async () => {
    // loading mode starts
    try {
      const data = await vendorsAPI({
        query: { limit: 10 },
      });
      setArtists(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      // loading mode completes
    }
  };

  useEffect(() => {
    getArtists();
  }, []);

  const rows =
    artists?.map((x) => ({
      id: x.id,
      displayName: x.displayName,
      createdAt: x.createdAt,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Display Name",
      sortable: true,
      minWidth: 240,
    },
    { field: "id", headerName: "Artist ID", minWidth: 240 },
    { field: "createdAt", headerName: "Created At", minWidth: 200 },
    {
      field: "actions",
      headerName: "",
      minWidth: 200,
      align: "right",
      renderCell: (params) => (
        <div className="flex justify-end gap-2">
          <Link to={routes.Artists.details(params.row.id).path}>
            <IconButton aria-label="view">
              <Visibility />
            </IconButton>
          </Link>
          <Link to={routes.Artists.edit(params.row.id).path}>
            <IconButton aria-label="edit">
              <Edit />
            </IconButton>
          </Link>
          <IconButton aria-label="edit">
            <MoreHorizRounded />
          </IconButton>
        </div>
      ),
      flex: 1,
    },
  ];

  return (
    <Page
      title="Artists"
      action={{
        label: "Create a New Artist",
        href: routes.MyArtists.create.path,
      }}
    >
      <XTable rows={rows} columns={columns} />
    </Page>
  );
};

export default ArtistsPage;
