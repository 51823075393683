import { AuthRoute } from "components/AuthRoute";
import IDEventCreatePage from "pages/Admin/EventUpdatePage";
import ArtistsPage from "pages/ArtistsPage";
import EventDetailsPage from "pages/EventDetailsPage";
import EventsPage from "pages/EventsPage";
import { Home } from "pages/Home";
import { Login } from "pages/Login";
import PrivateArtistsPage from "pages/MyArtistsPage";
import Register from "pages/Register";
import ArtistDetailsPage from "pages/ArtistDetailsPage";
import ArtistCreatePage from "pages/Admin/ArtistCreatePage";
import ArtistUpdatePage from "pages/Admin/ArtistUpdatePage";

const routes = {
  Home: {
    path: "/",
    component: (
      <AuthRoute>
        <Home />
      </AuthRoute>
    ),
  },
  Login: {
    path: "/login",
    component: <Login />,
  },
  Register: {
    path: "/register",
    component: <Register />,
  },
  // Vendors: {
  //   list: {
  //     path: "/vendors",
  //     component: <VendorsPage />,
  //   },
  //   details: (id = ":id") => ({
  //     path: `/vendors/${id}`,
  //     component: <ArtistDetailsPage />,
  //   }),
  // },
  Events: {
    list: {
      path: "/events/",
      component: <EventsPage />,
    },
    details: () => ({
      path: `/events/details/`,
      component: <EventDetailsPage />,
    }),
    // details: (id = ":id") => ({
    //   path: `/events/${id}`,
    //   component: (
    //     <AuthRoute>
    //       <EventDetailsPage />
    //     </AuthRoute>
    //   ),
    // }),
  },
  Vendors: {
    list: {
      path: "/vendors/",
      component: <EventsPage />,
    },
    details: () => ({
      path: `/vendors/details/`,
      component: <ArtistDetailsPage />,
    }),
  },
  Artists: {
    list: {
      path: "/artists/",
      component: <ArtistsPage />,
    },
    details: (id = ":id") => ({
      path: `/artists/${id}`,
      component: <ArtistDetailsPage />,
    }),
    create: {
      path: "/artists/create/",
      component: <ArtistCreatePage />,
    },
    edit: (id = ":id") => ({
      path: `/artists/edit/${id}`,
      component: <ArtistUpdatePage />,
    }),
  },
  MyArtists: {
    list: {
      path: "/myartists/",
      component: <PrivateArtistsPage />,
    },
    details: (id = ":id") => ({
      path: `/myartists/${id}`,
      component: <ArtistDetailsPage />,
    }),
    create: {
      path: "/myartists/create/",
      component: <ArtistCreatePage />,
    },
    edit: (id = ":id", userId = ":userId") => ({
      path: `/myartists/edit/${id}/${userId}`,
      component: <ArtistUpdatePage />,
    }),
  },
  Admin: {
    Events: {
      Create: {
        path: "/admin/events/create",
        // component: <AdminEventCreatePage />,
        component: <IDEventCreatePage />,
      },
    },
    Artists: {},
    //   Venues: {
    //     path: '/admin/venues/',
    //     component: <AdminVenuesPage />,
    //   },
  },
  // PrivacyPolicy: {
  //   path: '/privacy-policy',
  //   component: <>Privacy Policy</>,
  // },
  // adminArtists: '/admin/artists',
  // artistDetails: '/artists/:id',
  // adminEvents: '/admin/events',
  // events: '/events',
  // eventDetails: '/events/:id',
};

export default routes;
