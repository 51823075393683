import { Divider } from "@mui/material";
import React from "react";

interface XTitlesProps {
  items: XTitleProps[];
}
interface XTitleProps {
  label: string;
  value: React.ReactNode | string;
  child?: React.ReactNode | string;
  className?: string;
}

export const XTitle = (props: XTitleProps) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="text-white/50 text-sm leading-[24px]">{props.label}</div>
      <div className="text-lg font-medium leading-[36px]">{props.value}</div>
      {props.child && <div>{props.child}</div>}
    </div>
  );
};

export const XTitles = (props: XTitlesProps) => {
  return (
    <div className="flex items-center gap-5">
      {props.items.map((x, i) => (
        <React.Fragment key={i}>
          <XTitle label={x.label} value={x.value} child={x.child} />
          {props.items.length - 1 !== i && (
            <Divider
              orientation="horizontal"
              style={{ borderWidth: "1px", height: "40px" }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default XTitles;
