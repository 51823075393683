import { Box } from "@mui/material";

interface XFButtonProps {
  title: string;
  link?: string;
  icon?: string;
  variant?: "primary" | "secondary";
  size?: "sm" | "md" | "lg";
  isExternal?: boolean;
  width?: string | number;
  onClick?: (value?: any) => void;
  type?: "submit" | any;
  disabled?: boolean;
  mobileFull?: boolean;
}

const XFButton = ({
  variant = "primary",
  size = "md",
  ...props
}: XFButtonProps) => {
  const handleCardClick = () => {
    window.open(
      props.link,
      props.isExternal ? "_blank" : "_self",
      "noopener,noreferrer"
    );
  };
  const state = props.disabled ? "disabled" : "default";

  const baseClasses =
    "bg-primary rounded-[60px] font-semibold text-primaryOver cursor-pointer transition-all duration-500 transform whitespace-nowrap";

  const variantClasses = {
    primary: {
      default:
        "bg-primary border-primary border text-primary-text hover:bg-primary-hover",
      disabled:
        "bg-primary-disabled border-primary-disabled text-primary-disabled-text",
    },
    secondary: {
      default:
        "bg-transparent text-white border border-white hover:bg-white hover:text-black",
      disabled: "border-primary-disabled text-primary-disabled",
    },
  };

  const sizeClasses = {
    sm: "text-xxs px-4 py-1.5",
    md: "text-xs px-4 py-2",
    lg: "text-sm px-9 py-3.5",
  };

  const resolvedClasses = [
    baseClasses,
    variantClasses[variant][state],
    sizeClasses[size],
  ].join(" ");

  return (
    <button
      onClick={props.link ? handleCardClick : props.onClick}
      className={resolvedClasses}
      type={props.type}
      disabled={props.disabled}
      style={{ width: props.width || "min-content" }}
    >
      {props.icon && (
        <Box
          component="img"
          height="20px"
          width="20px"
          src={props.icon}
          alt={props.title}
        />
      )}
      <span>{props.title}</span>
    </button>
  );
};

export default XFButton;
